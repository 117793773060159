import { Account } from "@lib/ShiOneClient";
import { getPicklistValue } from "../../../../../functions/picklistValue";
import shiColors, { graphColors } from "shared-ui/src/theme/shiColors";
import { numberFormatterWithCurrency } from "shared-ui/src/numberFormatter";

export function getActiveAccounts(accounts: Account[] | undefined) {
  if (!accounts) {
    return [];
  }

  return accounts.filter((a) => a.active);
}

export function getAccountNamesFromAccounts(accounts: Account[] | undefined) {
  if (!accounts) {
    return [];
  }

  return accounts.map((a) => a.accountName!);
}

export function getBillingTypeLabel(
  contractType: number | undefined,
  picklist: any
) {
  return getPicklistValue(
    picklist.picklist,
    "contract",
    "contractType",
    contractType
  ).label;
}

export function getBillingTypeColor(
  contractType: number | undefined,
  picklist: any
) {
  const picklistValue = getPicklistValue(
    picklist.picklist,
    "contract",
    "contractType",
    contractType
  );

  if (!picklistValue) {
    return "";
  }

  const sortOrder = picklistValue.sortOrder;

  if (sortOrder < graphColors.length) {
    return graphColors[sortOrder];
  }

  return "";
}

export function getContractTypeNameColor(contractType: string | undefined) {
  switch (contractType?.toLowerCase()) {
    case "saas":
      return shiColors.pink.DEFAULT;
    case "service":
      return shiColors.blue.DEFAULT;
    default:
      return shiColors.gray.light;
  }
}

export function calculateContractDuration(
  start: Date | undefined,
  end: Date | undefined
) {
  if (!start || !end) {
    return 0;
  }

  const now = new Date();

  // Calculate how far we are into the contract in percentage
  if (now > end) {
    return 100;
  }

  if (now < start) {
    return 0;
  }

  return Math.floor(
    ((now.getTime() - start.getTime()) / (end.getTime() - start.getTime())) *
      100
  );
}

export function calculateMonthsBetweenDates(start: Date, end: Date) {
  return (
    end.getMonth() -
    start.getMonth() +
    12 * (end.getFullYear() - start.getFullYear())
  );
}

export function currentTotalCostLabels(
  currentCostParam: number,
  totalCostParam: number | undefined
) {
  const currentCost = numberFormatterWithCurrency.format(currentCostParam);
  if (!totalCostParam) return { currentCost };
  const costPercentage = (currentCostParam / totalCostParam) * 100;
  const totalCost = numberFormatterWithCurrency.format(totalCostParam);
  return { currentCost, totalCost, costPercentage };
}
