import { useLocation } from "react-router-dom";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRegisterDataGridSaveEvents } from "./useRegisterDataGridSaveEvents";
import { ShiGridColDefs } from "shared-ui/src/interfaces/ShiDataGridInterface";
import {
  useDeleteUserConfig,
  useGetUserConfig,
} from "../../modules/hooks/useUserConfig";
import { UserConfigDto } from "@lib/ShiOneClient";

export function useCXUserPrefs({
  gridId,
  apiRef,
  gridColsDef,
  rowExpandLookup,
  treeData,
  saveUserConfig,
}: {
  gridId: string | undefined;
  apiRef: any;
  gridColsDef: ShiGridColDefs;
  rowExpandLookup: any;
  treeData: boolean;
  saveUserConfig: boolean;
}) {
  const location = useLocation();
  const formattedGridId = gridId ? ":" + gridId : "";
  const configSettingsKey = saveUserConfig
    ? location.pathname + "_MuiGridSettings" + formattedGridId
    : "";
  const { userConfig, isLoading } = useGetUserConfig(configSettingsKey);
  const deleteUserConfig = useDeleteUserConfig();
  const localApiRef = useGridApiRef();
  if (!apiRef) apiRef = localApiRef;
  const {
    columnDefinitions,
    pinnedColumns,
    hiddenColumns,
    groupingColDef,
    sortedColumns,
  } = gridColsDef;

  const [initialState, setInitialState] = useState<any>();
  const [isDefaultView, setIsDefaultView] = useState<boolean>(true);
  const [userConfigSetting, setUserConfigSetting] = useState<UserConfigDto>();

  const hiddenColumnsObject = hiddenColumns?.reduce(
    (object: {}, item: string) => ({ ...object, [item]: false }),
    {}
  );

  const defaultInitialState = {
    columns: {
      columnVisibilityModel: hiddenColumnsObject,
    },
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 25,
      },
    },
    pinnedColumns: pinnedColumns,
    sorting: {
      sortModel: sortedColumns,
    },
  };

  const columns = useMemo<GridColDef[]>(() => {
    return columnDefinitions.map((columnDefinition: { [x: string]: any }) => {
      return Object.keys(columnDefinition).reduce((acc: any, key) => {
        if (columnDefinition[key] !== undefined) {
          if (key === "id") acc["field"] = columnDefinition[key];
          else acc[key] = columnDefinition[key];
        }
        return acc;
      }, {});
    }) as GridColDef[];
  }, [columnDefinitions]);

  const removeUnusedStates = (gridState: any) => {
    delete gridState.preferencePanel;
    delete gridState.pagination;
    delete gridState.filter;
  };
  useEffect(() => {
    if (!initialState) {
      setInitialState(apiRef.current.exportState());
    }
    if (userConfig && userConfig.settingsValue && initialState) {
      const parsedInitialState: any = JSON.parse(userConfig.settingsValue);
      setUserConfigSetting(userConfig);
      removeUnusedStates(parsedInitialState.gridState);
      removeUnusedStates(initialState);

      const isSavedEqualDefault =
        JSON.stringify(parsedInitialState.gridState) ===
        JSON.stringify(initialState);

      if (!isSavedEqualDefault) {
        apiRef.current.restoreState(parsedInitialState.gridState);
      }
      setIsDefaultView(isSavedEqualDefault);
    } else if (!isLoading && !userConfig && initialState) {
      apiRef.current.restoreState(initialState);
      setIsDefaultView(true);
    }
  }, [isLoading, userConfig, initialState, columnDefinitions]);

  // This hook must be below the two useEffects above that modify the grid
  // to prevent overriding the loaded state
  useRegisterDataGridSaveEvents(apiRef, gridId, saveUserConfig);

  useEffect(() => {
    if (apiRef?.current != null) {
      if (apiRef?.current.subscribeEvent != null) {
        return apiRef?.current?.subscribeEvent(
          "rowExpansionChange",
          (node: any) => {
            rowExpandLookup.current[node.id] = node.childrenExpanded;
          }
        );
      }
    }
  }, []);

  const handleResetTableView = async () => {
    if (initialState && userConfigSetting) {
      deleteUserConfig.mutate(userConfigSetting);
    }
  };

  const isGroupExpanded = useCallback(
    (row: any) => {
      return !!rowExpandLookup.current[row.id];
    },
    [rowExpandLookup]
  );

  const getTreeDataPath = (row: any) => row.hierarchy;
  const dataGridTreeDataProps: any = {};
  if (treeData) {
    dataGridTreeDataProps.treeData = true;
    dataGridTreeDataProps.groupingColDef = groupingColDef;
    dataGridTreeDataProps.isGroupExpandedByDefault = isGroupExpanded;
    dataGridTreeDataProps.getTreeDataPath = getTreeDataPath;
  }

  return {
    userConfigLoading: isLoading,
    handleResetTableView,
    isDefaultView,
    initialState,
    columns,
    defaultInitialState,
    dataGridTreeDataProps,
  };
}
